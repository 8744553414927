<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="d-flex">
        <back-button color="grey" @click="dialog = false" />
        Recuperação de senha
      </v-card-title>
      <v-card-text class="py-5">
        <v-form ref="form" @submit="handleSubmit">
          <v-text-field
            v-model="mail"
            label="Insira o e-mail cadastrado"
            :rules="[$rules.email]"
          />
          <div class="d-flex justify-center mt-5">
            <v-btn class="secondary" type="submit">
              Recuperar senha
              <v-icon class="ml-3">send</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { forgotPassword } from "@/services/external/users-service";

export default {
  name: "DialogForgotPassword",
  data: () => ({
    dialog: false,
    mail: "",
  }),
  methods: {
    open(mail) {
      if (mail) this.mail = mail;
      this.dialog = true;
    },
    async handleSubmit(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      try {
        this.$store.dispatch("loading/openDialog");
        await forgotPassword(this.mail);
        this.dialog = false;
        this.$toasted.global.success({
          message:
            "Enviamos um link para alterar sua senha no e-mail informado.",
        });
        this.$refs.form.reset();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped></style>
